@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,300&display=swap');

.wrapper {
    height: 100%;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    background: white;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin: 20px 10px 20px 20px;
}

.userWrapper {
    width: 100%;
    background-color: #703eff;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userContainer {
    height: 60px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 25px;
}

.inputWrapper {
    width: 100%;
    background-color: #703eff;
    box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    z-index: 1000;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputContainer {
    height: 60px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 25px;
}

input:focus {
    outline: none;
    border: none;
}

input {
    outline: none;
    border: none;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    background-color: #ffffff00;
}

.messageInput {
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    padding-left: 15px;

    width: 100%;
}

.messageInput::placeholder {
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.messageWrapper {
    width: 100%;

    display: flex;
    flex-direction: row;
    gap: 10px;

    border-radius: 10px;
    padding: 5px 15px;
}

.messageContainer {
    max-width: calc(100% - 50px);
    min-height: 40px;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    background: #703eff;
    padding: 5px 15px;
    border-radius: 10px;

    gap: 10px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 18px;

    color: #FFFFFF;

    cursor: pointer;
}

.messagesWrapper {
    width: 100%;
    position: relative;
    height: 100%;
}

.messagesContainer {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;

    overflow-y: scroll;
    scroll-behavior: smooth;

    height: calc(100% - 120px);

    padding-top: 10px;
    padding-bottom: 10px;
}

.messagesContainer::-webkit-scrollbar {
    width: 7px;
}

.messagesContainer::-webkit-scrollbar-thumb {
    background: #703eff;
}

.pickerContainer {
    position: absolute;
    bottom: 75px;
    left: 15px;

    z-index: 1000000;
    width: 300px;
}

.blobFileAdded {
    position: absolute;
    height: 70px;
    bottom: 75px;
    left: 15px;
    border-radius: 10px;
    padding: 10px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #703eff;
    backdrop-filter: blur(10px)
}

.blobFileAdded img, .blobFileAdded video {
    height: 50px;
    border-radius: 7px;
}

.deleteBlobFile {
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 50%;
    margin-left: 10px;

    cursor: pointer;
}

.fullSizedMediaMask {
    width: 100%;
    height: 100%;

    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
}