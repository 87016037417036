@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
}

body {
  background: #f7f7f7;
  color: black;

  padding: 0;
  margin: 0;
}
